import Glide from "@glidejs/glide";

(function($) {
    $(document).ready(function() {
        console.log("loaded");
        $('.menu-toggle').on('click', function() {
            var expanded = $(this).attr('aria-expanded');
            if (expanded === 'true') {
                $('.menu-main-menu-container').addClass('active');
            } else {
                $('.menu-main-menu-container').removeClass('active');
            }
        });

        /* 
    Enable Slick carousel 
    */

        new Glide('.glider-tools', {
            type: 'slider',
            rewind: true,
            perView: 3,
            gap: 40,
            bound: true,
            breakpoints: {
                769: {
                    perView: 1,
                    dots: '.dots',
                },
                1139: {
                    perView: 1,
                    dots: '.dots',
                },
            },
        }).mount();

        new Glide('.glide-logo', {
            type: 'carousel',
            rewind: true,
            perView: 3,
            autoplay: 3500,
            gap: 60,
        }).mount();


        /* End carousel */
    });
}(jQuery));